/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageColombia/pageAtencionCliente/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import Experience from "@components/pageColombia/pageAtencionCliente/experience/experience"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageColombia/pageAtencionCliente/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageContactCenter/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import transmisor from "@components/sharedComponents/testimonies/images/iconos/transmisor.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import transporte from "@components/sharedComponents/testimonies/images/iconos/transporte.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Industries from "@components/pageCasosdeUso/components/industries/industries"

const TemplatePageContactCenter = ({ location }) => {


  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    Beex te permite <TitleUnderlineRightOne underline="agilizar tu" /> <TitleUnderlineRightTwo underline="atención al cliente" /> mientras brindas una mejor experiencia

  </p>
  const titleTestimony = <p>
    Ayudamos a <TitleUnderlineTestimony underline="diferentes sectores " /> a fortalecer la relación con sus clientes con respuestas automatizadas </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas
      case 1:
        return logistica
      case 2:
        return transporte
      case 3:
        return salud
      case 4:
        return transmisor
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Logística"
      case 2:
        return "Transportes"
      case 3:
        return "Salud"
      case 4:
        return "Teleco"
      default:
        return "Conoce otros casos de éxito"
    }
  }


  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        
        <Header
          path="/soluciones/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/L6APC706ZXg?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={false} openVideo={false} linkPrice={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete al grupo de empresas en LATAM que ya potenciaron su atención al cliente con Beex" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Brinda experiencias únicas a tus clientes con tecnología omnicanal e IA"
            text="Nuestro equipo comercial está dispuesto a asesorarte en tu caso de uso específico."
            link="/hablemos/"
            textButton="Contacta con nosotros"
          />
          <Experience data={data.modules} location={location} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <BannerCTA
            title="Mejora tu CSAT y NPS con herramientas e información creada con IA"
            text="Nuestros expertos analizarán tus necesidades para ofrecerte soluciones personalizadas."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Industries data={data.industries} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageContactCenter
